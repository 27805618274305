import React, { useCallback, useEffect } from 'react';

import { FormHandles } from '@unform/core';
// import { BiTargetLock } from 'react-icons/bi';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UFs } from '../../../utils/StatesBR/UF';
import * as S from '../styles';
import * as C from './styles';
import {
  Input,
  InputRadio,
  Button,
  AutoSelectCreatable,
  // AutoSelect,
  // ProgressBar,
  InputMask,
} from '~/components';
import { IAutoSelectProps } from '~/models/Common';

import SeedsService from '~/services/Seeds';
// import { light } from '~/styles/themes';
import { ISingleFormLead } from '~/models/Auth';
import ViaCepService from '~/services/viaCep';
import { IAddress } from '~/models/viaCep';
import LimitInputField from '~/utils/functions/limitInputField';
import { formatToOptions } from '~/utils/functions';
import Select from '~/components/Select';

interface PreferencesProps {
  handleSubmit: (data: ISingleFormLead) => void;
  formRef: React.RefObject<FormHandles>;
  newOperatorOptions: string[];
  setNewOperatorOptions: (state: string[]) => void;
}

const Preferences: React.FC<PreferencesProps> = ({
  handleSubmit,
  formRef,
  newOperatorOptions,
  setNewOperatorOptions,
}) => {
  const [leadSend, setLeadSend] = useState<boolean>(false);
  const [answeredInputs, setAnsweredInputs] = useState(0);
  // const [showDates, setShowDates] = useState(false);
  // const [showAddress, setShowAddress] = useState(false);
  const [operatorOptions, setOperatorOptions] = useState<IAutoSelectProps[]>(
    [],
  );
  const [states, setStates] = useState<IAutoSelectProps[]>([]);
  const whatsappOptions = [
    { id: 1, label: 'Sim', value: true },
    { id: 2, label: 'Não', value: false },
  ];
  const [cepError, setCepError] = useState<boolean>(true);
  /* const [servicesOptions, setServicesOptions] = useState<IAutoSelectProps[]>(
    [],
  ); */
  const leadOptionsOne = [
    {
      id: '2187d5d7-6eb4-4a0b-8ec0-4dc24c9f0e13',
      label: 'Compra ',
      value: '2187d5d7-6eb4-4a0b-8ec0-4dc24c9f0e13',
    },
    {
      id: '5aee0511-bbf5-4ae2-9371-12c5a907208f',
      label: 'Novo Serviço',
      value: '2187d5d7-6eb4-4a0b-8ec0-4dc24c9f0e13',
    },
  ];
  const [leadOptions, setLeadOptions] = useState<IAutoSelectProps[]>([]);
  const [infoCep, setInfoCep] = useState<IAddress>();
  let autocomplete: google.maps.places.Autocomplete;
  function gtag_report_conversion(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    gtag('event', 'conversion', {
      send_to: 'AW-10801118213/7fWRCP_UsYIDEIX4r54o',
    });
    return false;
  }

  const loadCepInfo = useCallback(
    async (cep: string) => {
      try {
        if (cep.replace(/\D/g, '').length > 7) {
          const response = await ViaCepService.getAddress(cep);
          setInfoCep(response.data);
          setCepError(false);
          if (Object.keys(response.data).includes('erro')) {
            formRef.current?.setFieldValue('city', '');
            formRef.current?.setFieldValue('state', '');
            setCepError(true);
            toast.info('Não encontramos informações sobre seu cep');
          }
        } else {
          setCepError(true);
        }
      } catch (err) {
        toast.error('Houve um erro ao buscar seu cep');
      }
    },
    [formRef],
  );

  const fillInAddress = (): void => {
    formRef.current?.clearField('number');
    formRef.current?.clearField('street');
    formRef.current?.clearField('zipcode');
    formRef.current?.clearField('neighborhood');
    formRef.current?.clearField('city');
    formRef.current?.clearField('state');
    formRef.current?.clearField('complement');
    const place = autocomplete.getPlace();

    // eslint-disable-next-line no-restricted-syntax
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          formRef.current?.setFieldValue('number', component.long_name);
          break;
        }

        case 'route': {
          formRef.current?.setFieldValue('street', component.long_name);
          break;
        }

        case 'postal_code': {
          formRef.current?.setFieldValue('zipcode', component.long_name);
          break;
        }

        case 'sublocality_level_1':
        case 'sublocality_level_2': {
          formRef.current?.setFieldValue('neighborhood', component.long_name);
          break;
        }

        case 'administrative_area_level_4':
        case 'administrative_area_level_3':
        case 'administrative_area_level_2': {
          formRef.current?.setFieldValue('city', component.long_name);
          break;
        }

        case 'administrative_area_level_1': {
          formRef.current?.setFieldValue('state', component.long_name);
          break;
        }

        default: {
          break;
        }
      }
    }
  };

  useEffect(() => {
    const addressField = document.getElementById(
      'autocomplete',
    ) as HTMLInputElement;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    autocomplete = new google.maps.places.Autocomplete(addressField, {
      componentRestrictions: { country: ['br'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });

    autocomplete.addListener('place_changed', fillInAddress);
  }, [fillInAddress]);

  useEffect(() => {
    const getOperators = async (): Promise<void> => {
      const response = await SeedsService.getOperators();

      const optionsForInput = response.data
        .filter((operator) => operator.status)
        .map((operator) => ({
          id: operator.id,
          value: operator.id,
          label: operator.name,
        }));
      setOperatorOptions(optionsForInput);
    };

    getOperators();

    const formatedStates = formatToOptions(UFs, 'sigla', 'sigla');
    setStates(formatedStates);
  }, []);

  useEffect(() => {
    /* const getServices = async (): Promise<void> => {
      const response = await SeedsService.getServices();
      const optionsForInput = response.data.map((option) => ({
        id: option.id,
        value: option.id,
        label: option.name,
      }));
      setServicesOptions(optionsForInput);
    };

    getServices(); */
  }, []);

  useEffect(() => {
    const getLeadTypes = async (): Promise<void> => {
      const response = await SeedsService.getLeadTypes();
      const optionsForInput = response.data.map((option) => ({
        id: option.id,
        value: option.id,
        label: option.name,
      }));

      setLeadOptions(optionsForInput);
    };

    getLeadTypes();
  }, []);

  const handleChangeUF = (value: string): void => {
    const newValue = LimitInputField(value, 2);
    formRef.current?.setFieldValue('state', newValue);
  };

  /* const hasUrgencyOptions = [
    { id: 1, label: 'De imediato', value: true },
    { id: 2, label: 'Em horário agendado', value: false },
  ]; */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleShowInputs = useCallback(
    (inputNumber: number) => {
      if (inputNumber >= answeredInputs) {
        setAnsweredInputs(inputNumber);
      }
    },
    [answeredInputs],
  );

  /* const handleDateChange = useCallback(() => {
    const firstDateValue = formRef.current?.getFieldValue(
      'first_scheduling_date',
    );
    const secondDateValue = formRef.current?.getFieldValue(
      'second_scheduling_date',
    );
    if (firstDateValue && secondDateValue) {
      handleShowInputs(5);
    }
  }, [handleShowInputs, formRef]); */

  /* const handleServiceTypeChange = useCallback(() => {
    const value = formRef.current?.getFieldValue('has_urgency');
    if (value === 'true') {
      handleShowInputs(5);
      setShowDates(false);
    }
    if (value === 'false') {
      handleShowInputs(4);
      setShowDates(true);
    }
  }, [handleShowInputs, formRef]); */

  useEffect(() => {
    if (infoCep) {
      formRef.current?.setFieldValue('city', infoCep?.localidade);
      formRef.current?.setFieldValue('state', infoCep?.uf);
    }
  }, [infoCep, formRef]);

  // console.log('Submit', Preferences);

  return (
    <S.FormCard ref={formRef} onSubmit={handleSubmit}>
      <S.InputWrapper>
        <S.Label>Nome do contato ou empresa</S.Label>
        <Input name="name" />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>Atendimento de CPF, CNPJ e MEI</S.Label>
        <InputMask
          id="cnpj"
          name="cnpj"
          placeholder="CNPJ"
          mask="99.999.999/9999-99"
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>CEP</S.Label>
        <InputMask
          id="zipcode"
          name="zipcode"
          placeholder="CEP"
          type="tel"
          mask="99999-999"
          onChange={(value) => loadCepInfo(value.target.value)}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>UF</S.Label>
        {/* <Input
          name="state"
          placeholder="UF (Exemplo: RJ)"
          onChange={(event) => handleChangeUF(event.target.value)}
        /> */}
        <Select name="state" options={states} />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>Cidade</S.Label>
        <Input name="city" placeholder="Cidade" disabled={cepError} />
      </S.InputWrapper>
      <S.InputWrapper>
        <AutoSelectCreatable
          name="select_operators"
          options={operatorOptions}
          setOptions={setOperatorOptions}
          newOptions={newOperatorOptions}
          setNewOptions={setNewOperatorOptions}
          placeholder="Escolha um ou mais planos de saúde para atendimento."
          onChangeCallback={() => {
            handleShowInputs(2);
          }}
          style={{ marginBottom: '0.5rem' }}
        />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>Telefone</S.Label>
        <InputMask name="phone" type="tell" mask="(99) 9999-9999" />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>Deseja ser contatado pelo WhatsApp?</S.Label>
        <InputRadio name="contact_by_whatsapp" options={whatsappOptions} />
      </S.InputWrapper>
      <S.InputWrapper>
        <S.Label>Email</S.Label>
        <Input name="email" type="email" />
      </S.InputWrapper>
      <S.InputWrapper>
        <InputRadio
          name="lead_type_id"
          options={leadOptions}
          onChangeCallback={() => {
            handleShowInputs(6);
          }}
        />
      </S.InputWrapper>
      <C.ButtonWrapper>
        <Button
          id="submit-form-finished"
          containerStyle={{ maxWidth: 200, boxShadow: 'none' }}
          textStyle={{ fontWeight: 400 }}
          onClick={() => {
            if (!leadSend) {
              gtag_report_conversion();
              formRef.current?.submitForm();
              setLeadSend(true);
            }
          }}
        >
          Concluir
        </Button>
      </C.ButtonWrapper>
      {/*
        <S.InputWrapper>
          <S.Label>De onde você é?</S.Label>
          <Input
            id="autocomplete"
            name="address"
            placeholder="Digite seu endereço"
            icon={BiTargetLock}
            onChange={() => {
              setShowAddress(true);
              handleShowInputs(1);
            }}
          />
        </S.InputWrapper>
        {showAddress && (
          <C.AddressInputs>
            <S.InputWrapper>
              <S.Label>Estado</S.Label>
              <Input name="state" placeholder="Estado" />
            </S.InputWrapper>
            <S.InputWrapper>
              <S.Label>Cidade</S.Label>
              <Input name="city" placeholder="Cidade" />
            </S.InputWrapper>
            <S.InputWrapper>
              <S.Label>Bairro</S.Label>
              <Input name="neighborhood" placeholder="Bairro" />
            </S.InputWrapper>
            <S.InputWrapper>
              <S.Label>CEP</S.Label>
              <Input name="zipcode" placeholder="CEP" />
            </S.InputWrapper>
            <S.InputWrapper>
              <S.Label>Rua</S.Label>
              <Input name="street" placeholder="Rua" />
            </S.InputWrapper>
            <S.InputWrapper>
              <S.Label>N°</S.Label>
              <Input name="number" placeholder="N°" />
            </S.InputWrapper>
            <S.InputWrapper>
              <S.Label>Complemento</S.Label>
              <Input name="complement" placeholder="Complemento" />
            </S.InputWrapper>
          </C.AddressInputs>
        )}

        {answeredInputs > 0 && (
          <S.InputWrapper>
            <S.Label>Escolha sua(s) operadora(s)</S.Label>
            <AutoSelectCreatable
              name="select_operators"
              options={operatorOptions}
              setOptions={setOperatorOptions}
              newOptions={newOperatorOptions}
              setNewOptions={setNewOperatorOptions}
              placeholder="Selecione a operadora"
              onChangeCallback={() => {
                handleShowInputs(2);
              }}
            />
          </S.InputWrapper>
        )}

        {answeredInputs > 1 && (
          <S.InputWrapper>
            <S.Label>Escolha o(s) serviço(s) que deseja solicitar</S.Label>
            <AutoSelect
              name="select_services"
              options={servicesOptions}
              placeholder="Selecione o serviço"
              onChangeCallback={() => {
                handleShowInputs(3);
              }}
            />
          </S.InputWrapper>
        )}

        {answeredInputs > 2 && (
          <S.InputWrapper>
            <S.Label>Quando deseja ser atendido?</S.Label>
            <InputRadio
              name="has_urgency"
              options={hasUrgencyOptions}
              onChangeCallback={handleServiceTypeChange}
            />
          </S.InputWrapper>
        )}

        {showDates && (
          <S.InputWrapper>
            <S.Label>Selecione duas opções de data e horário</S.Label>
            <C.DateWrapper>
              <Input
                type="datetime-local"
                name="first_scheduling_date"
                style={{ paddingRight: 0 }}
                onChange={handleDateChange}
              />
              <Input
                type="datetime-local"
                name="second_scheduling_date"
                style={{ paddingRight: 0 }}
                onChange={handleDateChange}
              />
            </C.DateWrapper>
          </S.InputWrapper>
        )}

        {answeredInputs > 4 && (
          <S.InputWrapper>
            <S.Label>Selecione o tipo de atendimento</S.Label>
            <InputRadio
              name="lead_type_id"
              options={leadOptions}
              onChangeCallback={() => {
                handleShowInputs(6);
              }}
            />
          </S.InputWrapper>
        )}
      */}
    </S.FormCard>
  );
};

export default Preferences;
