import React, { useState, useCallback, useRef, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';

import { useHistory } from 'react-router-dom';
import Preferences from './Preferences';
import Contact from './Contact';
import Finish from './Finish';
import { getValidationErrors } from '~/utils/functions';
import { OrangeBaloon, WhiteBaloon } from '~/assets/icons';
import 'react-toastify/dist/ReactToastify.css';
import SessionService from '~/services/Session';
import LeadService from '~/services/Lead';
import { useLoading } from '~/hooks/loading';

import { IUserPreferences, IUserContact } from '~/models/User';
import { ILead, IRequestLeadData } from '~/models/Lead';
import { ModalBlack, Loading } from '~/components';
import { SeedsService } from '~/services';

import * as S from './styles';
import { UFs } from '~/utils/StatesBR/UF';
import { ISingleFormLead } from '~/models/Auth';

const Forms: React.FC = () => {
  const [isPreferencesFilled, setIsPreferencesFilled] = useState(false);
  const [showFinish, setShowFinish] = useState(false);
  const [preferencesData, setPreferencesData] = useState<IUserPreferences>(
    {} as IUserPreferences,
  );
  const [newOperatorOptions, setNewOperatorOptions] = useState<string[]>([]);
  const [finishModal, setFinishModal] = useState<boolean>(false);
  const [sellerFound, setSellerFound] = useState<boolean>(false);
  const [leadRequested, setLeadRequested] = useState<ILead[]>();
  const formRef = useRef<HTMLDivElement | null>(null);

  const { setLoading, isLoading } = useLoading();
  const router = useHistory();
  const preferencesFormRef = useRef<FormHandles>(null);
  const contactFormRef = useRef<FormHandles>(null);

  const handleApiConnection = useCallback(
    async (contactData): Promise<void> => {
      try {
        setLoading(true);
        const response = await SessionService.signupClient({
          ...contactData,
        });
        const { token } = response.data;
        toast.success('Usuário cadastrado!');

        // Se houver sugestões de operadoras, devem ser feitas antes de criar a lead
        if (
          preferencesData.newOperators &&
          preferencesData.newOperators.length > 0
        ) {
          const newOperatorsIds: string[] = [];
          const { newOperators } = preferencesData;
          newOperators?.forEach(async (newOperator) => {
            const suggestionResponse = await LeadService.suggestOperador(
              newOperator,
              token,
            );
            newOperatorsIds.push(suggestionResponse.data.id);
          });
          preferencesData.operators = [
            ...preferencesData.operators,
            ...newOperatorsIds,
          ];
          toast.success('Sugestões de operadoras enviadas!');
        }

        const lead = await SeedsService.getLeadTypes().then((r) =>
          r.data.find((item) => item.id === preferencesData.lead_type_id),
        );

        const leadData: IRequestLeadData = {
          ...preferencesData,
          contact_by_whatsapp: contactData.contact_by_whatsapp,
          zip_code: preferencesData.zipcode,
          type: lead?.credit_price === 20 ? 'PURCHASE' : 'RENEW',
        };

        await LeadService.requestLead(leadData, token).then(
          async (leadRequestedResponse) => {
            await setLeadRequested(leadRequestedResponse.data);
            setLoading(false);
            setFinishModal(true);
            setShowFinish(true);
          },
        );
      } catch (error: any) {
        if (error) {
          setLoading(false);
          toast.error(`${error.response}`);
        }
      }
    },
    [preferencesData, setLoading],
  );

  const handleSubmitPreferences = useCallback(
    async (data: IUserPreferences) => {
      try {
        const schema = Yup.object().shape({
          address: Yup.string().required('Endereço obrigatório'),
          state: Yup.string()
            .test(
              'FindInitial',
              'Não encontramos o estado informado',
              (value) => !!UFs.find((el) => el.nome === value)?.sigla,
            )
            .required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatória'),
          neighborhood: Yup.string().required('Bairro é obrigatório'),
          street: Yup.string().required('Rua é obrigatória'),
          number: Yup.number().required('Número é obrigatório').nullable(),
          zipcode: Yup.string().required('CEP é obrigatório'),
          complement: Yup.string(),
          select_operators: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string(),
                value: Yup.string(),
                label: Yup.string(),
              }),
            )
            .min(1)
            .required('Operadoras obrigatório'),
          select_services: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string(),
                value: Yup.string(),
                label: Yup.string(),
              }),
            )
            .min(1)
            .required('Serviços obrigatórios'),
          has_urgency: Yup.boolean().required(
            'Nível de urgência é obrigatório.',
          ),
          first_scheduling_date: Yup.string(),
          second_scheduling_date: Yup.string(),
          lead_type_id: Yup.string().required(
            'Tipo de atendimento obrigatório',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // Setando o formato para api:
        data.operators = data.select_operators
          .filter((operator) => operator.id !== 'default')
          .map((operator) => operator.value);
        data.services = data.select_services.map((service) => service.value);
        data.newOperators = newOperatorOptions;
        data.state = UFs.find((el) => el.nome === data.state)?.sigla ?? '';

        setIsPreferencesFilled(true);
        setPreferencesData(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          Object.values(errors).forEach((value: string) => {
            toast.error(`${value}`);
          });

          preferencesFormRef.current?.setErrors(errors);
          // console.log(errors);
        }
      }
    },
    [newOperatorOptions],
  );

  const handleSubmitSingleForm = useCallback(
    async (data: ISingleFormLead) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string().required('CNPJ é obrigatório'),
          zipcode: Yup.string().required('CEP é obrigatório'),
          state: Yup.string().required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatória'),
          select_operators: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string(),
                value: Yup.string(),
                label: Yup.string(),
              }),
            )
            .min(1)
            .required('planos é um campo obrigatório'),
          phone: Yup.string()
            .matches(/^\d{10,12}$/, 'Número inválido')
            .required('Celular obrigatório.'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório.'),
          lead_type_id: Yup.string()
            .required('Tipo de atendimento obrigatório')
            .nullable(),
        });

        await schema.validate(
          {
            ...data,
            phone: data.phone.replace(/\D/g, ''),
          },
          {
            abortEarly: false,
          },
        );

        // Setando o formato para api:
        data.operators = data.select_operators
          .filter((operator) => operator.id !== 'default')
          .map((operator) => operator.value);
        data.newOperators = newOperatorOptions;
        data.phone = data.phone.replace(/\D/g, '');
        data.cnpj = data.cnpj.replace(/\D/g, '');

        const response = await SessionService.signupClient({
          accept_terms: true,
          email: data.email,
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        const { token } = response.data;
        toast.success('Usuário cadastrado!');

        if (data.newOperators.length > 0) {
          const newOperatorsIds: string[] = [];
          const { newOperators } = data;
          newOperators?.forEach(async (newOperator) => {
            const suggestionResponse = await LeadService.suggestOperador(
              newOperator,
              token,
            );
            newOperatorsIds.push(suggestionResponse.data.id);
          });
          data.operators = [...data.operators, ...newOperatorsIds];
          toast.success('Sugestões de operadoras enviadas!');
        }

        const lead = await SeedsService.getLeadTypes().then((r) =>
          r.data.find((item) => item.id === data.lead_type_id),
        );

        const leadData: IRequestLeadData = {
          ...data,
          has_urgency: false,
          lead_type_id: data.lead_type_id,
          state: data.state,
          city: data.city,
          neighborhood: '',
          street: '',
          number: 0,
          zip_code: data.zipcode.replace(/\D/g, ''),
          type: lead?.credit_price === 20 ? 'PURCHASE' : 'RENEW',
        };

        await LeadService.requestLead(leadData, token).then(
          async (leadRequestedResponse) => {
            await setLeadRequested(leadRequestedResponse.data);
            setLoading(false);
            setFinishModal(true);
            setShowFinish(true);
          },
        );

        window.fbq('track', 'Lead');
        setIsPreferencesFilled(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          setLoading(false);
          Object.values(errors).forEach((value: string) => {
            toast.error(`${value}`);
          });

          preferencesFormRef.current?.setErrors(errors);
          console.log(errors);
        }
      }
    },
    [newOperatorOptions, setLoading],
  );

  const handleSubmitContact = useCallback(
    async (data: IUserContact) => {
      data.phone = data.phone.replace(/\D/g, '');
      if (data.accept_terms.length === 0) {
        data.accept_terms = false;
      } else {
        data.accept_terms = true;
      }

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório.'),
          phone: Yup.string()
            .matches(/^\d{10,12}$/, 'Número inválido')
            .required('Celular obrigatório.'),
          contact_by_whatsapp: Yup.string()
            .nullable()
            .required('Opção de contato obrigatória'),
          accept_terms: Yup.boolean()
            .oneOf([true], 'Aceitar os termos de uso é obrigatório')
            .required('Aceitar os termos de uso é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        handleApiConnection(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          Object.values(errors).forEach((value: string) => {
            toast.error(`${value}`);
          });

          contactFormRef.current?.setErrors(errors);
        }
      }
    },
    [handleApiConnection],
  );

  const handleGetLead = useCallback(async () => {
    const newArrLeads = [] as ILead[];
    await leadRequested?.forEach(async (lead, index) => {
      await LeadService.getLeadById(lead.id).then((response) => {
        newArrLeads.push(response.data);
      });
      if (index === leadRequested.length - 1) {
        setLeadRequested(newArrLeads);
      }
    });
  }, [leadRequested]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFindSaller = useCallback(async () => {
    if (sellerFound) return;
    if (leadRequested?.length) {
      if (
        !leadRequested.find((lead) => lead.status !== 'Pending') &&
        !sellerFound
      ) {
        const timer = setTimeout(handleGetLead, 10000);
      } else {
        setFinishModal(false);
        setSellerFound(true);
      }
    }
  }, [handleGetLead, leadRequested, sellerFound]);

  useEffect(() => {
    if (leadRequested) handleFindSaller();
  }, [showFinish, leadRequested, handleFindSaller]);

  useEffect(() => {
    if (window.location.hash) {
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formRef]);

  return (
    <>
      <S.Container id="formdiv" ref={formRef}>
        <ToastContainer />
        <S.BgImage className="bg">
          <img src={OrangeBaloon} alt="Background Balão" />
        </S.BgImage>
        <S.OrangeBg>
          <S.InputWrapper>
            <S.OrangeWrapper>
              <img src={WhiteBaloon} alt="Ícone balão" />
              <h1>
                Somos especializados na venda de Planos de Saúde de forma
                consultiva e rápida. Venha para a Clientcom e encontre as
                melhores soluções para você e sua empresa
                <br /> <br />
                <br />O atendimento será realizado em até{' '}
                <strong>5 minutos</strong> durante o horário comercial. Fora de
                horário comercial, o prazo de atendimento é de até{' '}
                <strong>24h</strong>.
              </h1>
            </S.OrangeWrapper>
            <S.FormWrapper>
              {!isPreferencesFilled && !showFinish && (
                <Preferences
                  handleSubmit={handleSubmitSingleForm}
                  formRef={preferencesFormRef}
                  newOperatorOptions={newOperatorOptions}
                  setNewOperatorOptions={setNewOperatorOptions}
                />
              )}

              {isPreferencesFilled && !showFinish && (
                <Contact
                  handleSubmit={handleSubmitContact}
                  setPreferencesFilled={setIsPreferencesFilled}
                  formRef={contactFormRef}
                />
              )}
              {showFinish && (
                <Finish
                  modalIsVisible={finishModal}
                  setModalIsVisible={setFinishModal}
                  sellerFound={sellerFound}
                />
              )}
            </S.FormWrapper>
          </S.InputWrapper>
        </S.OrangeBg>
      </S.Container>
      <ModalBlack
        modalVisible={isLoading}
        setModalVisible={setLoading}
        containerStyle={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          justifyContent: 'center',
        }}
        backdropStyle={{ backgroundColor: '#1e1e1ee6' }}
      >
        <Loading loading={isLoading} size={150} />
        <S.ModalText style={{ fontWeight: 700, marginTop: 20 }}>
          Estamos buscando o vendedor
        </S.ModalText>
        <S.ModalText>perfeito pra você!</S.ModalText>
      </ModalBlack>
    </>
  );
};

export default Forms;
