import {
  ReactElement,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { ArrowRightRed, Order } from '~/assets/icons';
import { Table, Pagination } from '~/components';
import { ISeller } from '~/models/Seller';
import {
  ISellerPageOptionsRequest,
  IPagination,
  SellersSortBy,
} from '~/models/Common';
import { AdminService } from '~/services';
import { handleFormatCEP, handleFormatPhoneNumber } from '~/utils/functions';

import * as S from './styles';

type SellerStatus = 'PENDING' | 'ACTIVE';

function Sellers(): ReactElement {
  const [sellers, setSellers] = useState<IPagination<ISeller[]>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: '0',
  });
  const [sellerStatus, setSellerStatus] = useState<SellerStatus>('PENDING');
  const [sortByFilter, setSortByFilter] = useState<SellersSortBy>('name');
  const [pageOptions, setPageOptions] = useState<ISellerPageOptionsRequest>({
    page: 1,
    active: true,
    sortBy: 'name',
  });
  const router = useHistory();
  const [direction, setDirection] = useState<string>('asc');

  const handleDirection = (): void => {
    setDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  const goToDetailPageButtonStyle = useRef<CSSProperties>({
    width: 9,
    height: 19,
  });

  const handleGetSellers = useCallback(
    async (active: boolean, status?: SellerStatus) => {
      try {
        const response = await AdminService.getAllSellers({
          ...pageOptions,
          sortBy: sortByFilter,
          active,
          direction,
        });
        status ? setSellerStatus(status) : null;
        setSellers(response.data);
      } catch (error) {
        toast.error(`Não foi possível listar os vendedores.`);
      }
    },
    [pageOptions, sortByFilter, direction],
  );

  const handleAcceptRecuseSeller = useCallback(
    async (sellerId: string, active: boolean) => {
      try {
        await AdminService.putAcceptRecuseSeller(sellerId, active);
        active
          ? toast.success('Vendedor aprovado.')
          : toast.success('Vendedor reprovado.');
        handleGetSellers(sellerStatus !== 'PENDING');
      } catch (error) {
        toast.error(`Não foi possível aprovar ou recusar o vendedor.`);
      }
    },
    [handleGetSellers, sellerStatus],
  );

  const handleGoToActiveSellerPage = useCallback(
    (leadId: string) => {
      router.push(`/admin/sellers/${leadId}`);
    },
    [router],
  );

  useEffect(() => {
    handleGetSellers(sellerStatus !== 'PENDING');
  }, [handleGetSellers, pageOptions, sellerStatus, sortByFilter]);

  const SellerTableMemo = useMemo(
    () =>
      sellerStatus === 'PENDING' ? (
        <Table
          key={0}
          noItems={sellers?.data?.length === 0}
          tableHeadChildren={() => (
            <>
              <th
                onClick={() => {
                  setSortByFilter('name');
                  handleDirection();
                }}
              >
                Nome completo{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('email');
                  handleDirection();
                }}
              >
                E-mail{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('phone');
                  handleDirection();
                }}
              >
                Telefone{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('operators');
                  handleDirection();
                }}
              >
                Operadora{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('cep');
                  handleDirection();
                }}
              >
                CEP{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th> </th>
              <th> </th>
            </>
          )}
        >
          {sellers?.data?.map((seller) => (
            <tr key={seller.id}>
              <td>{seller?.user?.name ?? '-'}</td>
              <td>{seller?.user?.email ?? '-'}</td>
              <td>{handleFormatPhoneNumber(seller?.user?.phone ?? '')}</td>
              <td>
                {seller?.operators && seller?.operators.length > 0
                  ? seller?.operators.map(
                      (operator, index) =>
                        `${operator.name} ${
                          index === seller.operators.length - 1 ? '' : '-'
                        }`,
                    )
                  : '-'}
              </td>
              <td>
                {seller.address.zip_code
                  ? handleFormatCEP(seller.address.zip_code)
                  : '-'}
              </td>
              <td>
                <S.TableButton
                  type="button"
                  onClick={() => handleAcceptRecuseSeller(seller.id, true)}
                >
                  Aprovar
                </S.TableButton>
              </td>
              <td>
                <S.TableButton
                  type="button"
                  onClick={() => handleAcceptRecuseSeller(seller.id, false)}
                >
                  Recusar
                </S.TableButton>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <Table
          key={1}
          noItems={sellers?.data?.length === 0}
          tableHeadChildren={() => (
            <>
              <th
                onClick={() => {
                  setSortByFilter('name');
                  handleDirection();
                }}
              >
                Nome completo
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('email');
                  handleDirection();
                }}
              >
                E-mail{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('phone');
                  handleDirection();
                }}
              >
                Telefone{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('operators');
                  handleDirection();
                }}
              >
                Operadora{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('credits');
                  handleDirection();
                }}
              >
                Créditos{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByFilter('leads');
                  handleDirection();
                }}
              >
                Leads atendidos{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th> </th>
            </>
          )}
        >
          {sellers?.data?.map((seller) => (
            <tr
              key={seller.id}
              onClick={() => handleGoToActiveSellerPage(seller.id)}
            >
              <td>{seller?.user?.name ?? '-'}</td>
              <td>{seller?.user?.email ?? '-'}</td>
              <td>{handleFormatPhoneNumber(seller?.user?.phone)}</td>
              <td>
                {seller?.operators && seller?.operators.length > 0
                  ? seller?.operators.map(
                      (operator, index) =>
                        `${operator.name} ${
                          index === seller.operators.length - 1 ? '' : '-'
                        }`,
                    )
                  : '-'}
              </td>
              <td>R$ {seller.credits ?? '-'}</td>
              <td>{seller.leads_qnt ?? '-'}</td>
              <td>
                <img
                  src={ArrowRightRed}
                  alt="Ir para a página do vendedor"
                  style={goToDetailPageButtonStyle.current}
                />
              </td>
            </tr>
          ))}
        </Table>
      ),
    [
      handleAcceptRecuseSeller,
      handleGoToActiveSellerPage,
      sellerStatus,
      sellers,
    ],
  );

  return (
    <>
      <S.Container>
        <S.Title>Vendedores</S.Title>
        <S.TableWrapper>
          <S.TableHeader>
            <S.TableWrapperButton
              onClick={() => handleGetSellers(false, 'PENDING')}
              isInactive={sellerStatus === 'ACTIVE'}
            >
              Aguardando aprovação
            </S.TableWrapperButton>
            <S.TableWrapperButton
              onClick={() => handleGetSellers(true, 'ACTIVE')}
              isInactive={sellerStatus === 'PENDING'}
            >
              Ativos
            </S.TableWrapperButton>
          </S.TableHeader>
          {SellerTableMemo}
          <S.TableFooter>
            <Pagination
              pageCount={sellers.lastPage ?? 0}
              pageRangeDisplayed={pageOptions.page}
              {...{ setPageOptions }}
            />
          </S.TableFooter>
        </S.TableWrapper>
      </S.Container>
    </>
  );
}

export default Sellers;
